import { API } from "../../constans/Url"
import { apiClient } from "../../helper"


const get_ListProvinsi = (callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.PROVINSI).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ListKabupaten = (id_prov, callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.KABUPATEN, {id_prov: id_prov}).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ListKecamatan = (id_kab, callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.KECAMATAN, {id_kab: id_kab}).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ListDivisi = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.DIVISI, params).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ListTransaksiTipe = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.TRANSAKSI_TIPE, params).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ListBank = (callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.BANK).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ListJenjangPendidikan = (callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.JENJANG_PENDIDIKAN).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ListStatusPernikahan = (callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.STATUS_PERNIKAHAN).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_SatuanList = (callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.SATUAN_BARANG).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PeriodeAkuntansi = (callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.PERIODE_AKUNTANSI).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_ListProvinsi,
  get_ListKabupaten,
  get_ListKecamatan,
  get_ListDivisi,
  get_ListTransaksiTipe,
  get_ListBank,
  get_ListJenjangPendidikan,
  get_ListStatusPernikahan,
  get_SatuanList,
  get_PeriodeAkuntansi
}